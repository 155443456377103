<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      show-search-filter
      :show-operations="false"
      no-meta-check
      route-base="/products/product_templates"
      resource="productTemplates"
      :resource-query="{ query: { deleted: false } }"
      :buttons="computedButtons"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('pages.product_templates.all.title')
    }
  },

  data() {
    return {
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          truncate: true
        },
        {
          field: 'display_options',
          label: this.$t(
            'pages.product_templates.all.headers.display_options.title'
          ),
          fallback: '-',
          truncate: true,
          formatter: (row, column) => {
            if (!row || !row.option_template) return '-'

            return `${this.$t(
              'pages.product_templates.all.headers.display_options.attributes'
            )}: ${Object.keys(row.option_template).join(', ')}`
          }
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['products:product_templates:create']
        }
      ]
    }
  },

  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.product_templates.title')
        })
      })
    }
  }
}
</script>
